<template>
  <div class="new_container">
    <div class="top">
      <nav-header fixed :title="title"></nav-header>
    </div>
    <div class="body " style="background: #fff">
      <div class="max-width">
        <div class="tal-text-20px tal-font-bold" >
          {{ newDetail.name }}
        </div>
        <div class="sub_content">
          <div class="sub_content_left tal-text-15px">
            <span style="margin-right:10px;" class="tal-text-15px">来源:</span>
            <span class="tal-text-15px">{{ newDetail.lai_yuan }}</span>
          </div>
          <div class="sub_content_right">
            <span style="margin-right:10px;" class="tal-text-15px">发布时间:</span>
            <span class="tal-text-15px">{{ newDetail.create_time }}</span>
          </div>
        </div>
        <div class="body_content" v-html="newDetail.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from '@/components/top/NavHeader'
import http from "@/api/http";
import wei_xin_basis, {hiddenWeiXinFenXiang, weiXinFenXiang} from "@/api/QiYeWeiXi";
import _ from 'lodash'
import to from 'await-to-js'

export default {
  name: "NewsDetail",
  data() {
    return {
      title: '新闻详情',
      newDetail: {}, //详情内容
    }
  },
  components: {
    NavHeader
  },
  beforeRouteLeave(to, from, next) {
    hiddenWeiXinFenXiang()
    next()
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.id) {
        this.getNewDetail(this.$route.query.id)
      }
    })
  },
  methods: {
    async getNewDetail(paramsId) {
      const [e, res] = await to(http.get(`/gzhphp/wen_zhan/getNewsInfo?id=${paramsId}`))
      if (e || _.get(res, 'code') != 1) return
      this.newDetail = res.data

      await wei_xin_basis.setConfig()
      weiXinFenXiang({
        title: _.get(res.data, 'name'),
        desc: _.get(res.data, 'jian_jie'),
        imgUrl: _.get(res.data, 'image.0.url'),
        link: location.href
      })

    }
  }

}
</script>

<style scoped lang="less">
@import "../../../assets/da-ting.less";

/deep/ .body {
  background: #fff;
}

.new_container {
  background-color: white;
  height: 100%;
}

.body {
  padding: 10px;

  .body_title {
    font-size: 16px;
    font-weight: 700;
  }

  .sub_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #969799;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

</style>
